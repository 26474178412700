import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { Lock } from "@mui/icons-material";

import Layout from "../../components/layout/layout";
import {
  H3,
  H5,
  H6,
  TextSm,
  TextXs,
} from "../../components/theme/text/Headings";
import {
  BlurredBackground,
  BlurredOverlay,
  BreakdownBody,
  BreakdownBottom,
  BreakdownContainer,
  BreakdownHeader,
  ResultsPageBox,
  UpgradeBoxLocked,
  UpgradeContainer,
  UpgradeHeader,
} from "../../components/theme/QuizBox";
import Axis from "../../components/theme/axis";
import { DarkOrange } from "../../components/theme/colors";
import { ButtonBrown } from "../../components/theme/Button";
import { SecPic } from "../Home";

import { auth, db } from "../../services/firebase.config";

const ProPic = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px solid ${DarkOrange};
`;

const ResultsMB = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true); // Loading state
  const [currentUser, setCurrentUser] = useState(null); // Track current user
  const [username, setUsername] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [quizId, setQuizId] = useState("");

  const [quizId2, setQuizId2] = useState("");
  const [displayName2, setDisplayName2] = useState("");
  const [photoURL2, setPhotoURL2] = useState("");
  const [username2, setUsername2] = useState("");

  const [searchParams] = useSearchParams();
  const sharedId = searchParams.get("shared");

  const [percentages, setPercentages] = useState([]);

  const handlePercentageUpdate = (field, percentage1, percentage2) => {
    setPercentages((prev) => [
      ...prev.filter((item) => item.field !== field), // Remove any existing field data
      { field, percentage1, percentage2 },
    ]);
  };

  const determineTrait = (field, percentage) => {
    if (field === "introversion") {
      return percentage > 50 ? "I" : "E";
    }
    if (field === "intuition") {
      return percentage > 50 ? "N" : "S";
    }
    if (field === "feeling") {
      return percentage > 50 ? "F" : "T";
    }
    if (field === "perception") {
      return percentage > 50 ? "P" : "J";
    }
    return "Unknown";
  };

  // Listen for auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (!user) setLoading(false); // Stop loading if no user
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUser) return;

      const profileDocRef = doc(db, "public-profiles", currentUser.uid);
      const profileDoc = await getDoc(profileDocRef);

      if (profileDoc.exists()) {
        const data = profileDoc.data();
        setUsername(data.username || "");
        setPhotoURL(data.photoURL || "");
        setDisplayName(data.displayName || "");

        setQuizId(data.myersBriggsQuizId);
      }
    };

    if (currentUser) fetchProfileData();
  }, [currentUser]);

  useEffect(() => {
    const getSharedCollection = async () => {
      if (!sharedId || !currentUser) {
        setLoading(false); // Stop loading if no shared ID or user
        return;
      }

      if (sharedId) {
        const sharedDocRef = doc(db, "shared-quizzes", sharedId);
        const sharedDoc = await getDoc(sharedDocRef);

        if (sharedDoc.data().senderUid === currentUser.uid) {
          setQuizId2(sharedDoc.data().receiverQuizId);

          const profile2DocRef = doc(
            db,
            "public-profiles",
            sharedDoc.data().receiverUid
          );
          const profile2Doc = await getDoc(profile2DocRef);

          if (profile2Doc.exists()) {
            const data = profile2Doc.data();
            setUsername2(data.username || "");
            setPhotoURL2(data.photoURL || "");
            setDisplayName2(data.displayName || "");
          }
        } else if (sharedDoc.data().senderUid !== currentUser.uid) {
          setQuizId2(sharedDoc.data().senderQuizId);

          const profile2DocRef = doc(
            db,
            "public-profiles",
            sharedDoc.data().senderUid
          );
          const profile2Doc = await getDoc(profile2DocRef);

          if (profile2Doc.exists()) {
            const data = profile2Doc.data();
            setUsername2(data.username || "");
            setPhotoURL2(data.photoURL || "");
            setDisplayName2(data.displayName || "");
          }
        }
      }
      setLoading(false);
    };
    getSharedCollection();
  }, [sharedId, currentUser, quizId2]);

  if (loading) {
    // Show a loading spinner or placeholder
    return (
      <Layout>
        <Container>
          <Row className="mt-10 text-align-center">
            <Col>
              <H5>Loading your results...</H5>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container>
        <Row>
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <ResultsPageBox style={{ flexDirection: "column" }}>
              <TextSm>Quiz Results</TextSm>
              <H5 className="mb-20 mt-5-real">
                What's your Myers-Briggs personality type?
              </H5>

              {!sharedId ? (
                <div className="display-flex display-column">
                  {photoURL ? (
                    <ProPic src={photoURL} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName ? displayName[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                  <div className="display-flex" style={{ gap: "5px" }}>
                    {percentages.map((item) => {
                      return (
                        <H6 key={item.field}>
                          {item.percentage1 !== undefined
                            ? determineTrait(item.field, item.percentage1)
                            : "N/A"}
                        </H6>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="display-flex">
                  <div className="display-flex display-column">
                    {photoURL ? (
                      <ProPic src={photoURL} />
                    ) : (
                      <SecPic>
                        <H3 className="mb-0">
                          {displayName ? displayName[0] : "?"}
                        </H3>
                      </SecPic>
                    )}
                    <div className="display-flex" style={{ gap: "5px" }}>
                      {percentages.map((item) => {
                        return (
                          <H6 key={item.field}>
                            {item.percentage1 !== undefined
                              ? determineTrait(item.field, item.percentage1)
                              : "N/A"}
                          </H6>
                        );
                      })}
                    </div>
                  </div>
                  <CompareArrowsIcon className="mt-15" />
                  <div className="display-flex display-column">
                    {photoURL2 ? (
                      <ProPic src={photoURL2} />
                    ) : (
                      <SecPic>
                        <H3 className="mb-0">
                          {displayName2 ? displayName2[0] : "?"}
                        </H3>
                      </SecPic>
                    )}
                    <div className="display-flex" style={{ gap: "5px" }}>
                      {percentages.map((item) => {
                        return (
                          <H6 key={item.field}>
                            {item.percentage2 !== undefined
                              ? determineTrait(item.field, item.percentage2)
                              : "N/A"}
                          </H6>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </ResultsPageBox>
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <BreakdownContainer>
              <BreakdownHeader background={DarkOrange}>
                <H6 className="mb-0">
                  Score Breakdown:{" "}
                  {username ? `@${username}` : `${displayName}`}{" "}
                  {sharedId && (
                    <> {username2 ? `+ @${username2}` : `+ ${displayName2}`}</>
                  )}
                </H6>
              </BreakdownHeader>
              <BreakdownBody>
                <Axis
                  quizName="myers-briggs"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Extraversion (E)"
                  rightLabel="Introversion (I)"
                  leftDescription="Thrives on social interaction."
                  rightDescription="Energized by solitude, often reflecting inwardly."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="introversion"
                  onPercentageUpdate={handlePercentageUpdate}
                />
                <Axis
                  quizName="myers-briggs"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Sensing (S)"
                  rightLabel="Intuition (N)"
                  leftDescription="Focused on the present and concrete details."
                  rightDescription="Open to future possibilities and abstract concepts."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="intuition"
                  onPercentageUpdate={handlePercentageUpdate}
                />
                <Axis
                  quizName="myers-briggs"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Thinking (T)"
                  rightLabel="Feeling (F)"
                  leftDescription="Prioritizes logic, objectivity, and fairness."
                  rightDescription="Prioritizes values, empathy, and harmony."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="feeling"
                  onPercentageUpdate={handlePercentageUpdate}
                />
                <Axis
                  quizName="myers-briggs"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Judging (J)"
                  rightLabel="Perception (P)"
                  leftDescription="Needs structure, planning, and organization."
                  rightDescription="Prefers spontaneity and adaptability."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="perception"
                  onPercentageUpdate={handlePercentageUpdate}
                />
              </BreakdownBody>
            </BreakdownContainer>
          </Col>
        </Row>

        {(currentUser || !currentUser?.isAnonymous) && sharedId && (
          <Row>
            <Col
              lg={{ offset: 4, span: 4 }}
              md={{ offset: 3, span: 6 }}
              sm={{ offset: 2, span: 8 }}
              xs={{ span: 12 }}
            >
              <UpgradeContainer>
                <UpgradeHeader background={DarkOrange}>
                  <H6 className="mb-0">Your Compatability Insights </H6>
                </UpgradeHeader>
                <UpgradeBoxLocked>
                  <BlurredBackground>
                    <H6> Core Personality Compatability: </H6>
                    <TextXs>
                      {username ? `@${username}` : `${displayName}`}{" "}
                      {sharedId && (
                        <>
                          {" "}
                          {username2 ? `+ @${username2}` : `+ ${displayName2}`}
                        </>
                      )}
                    </TextXs>

                    <TextXs className="mt-20">
                      About {username ? `@${username}` : `${displayName}`}:
                      Bacon ipsum dolor amet ground round sausage kevin
                      tenderloin pork loin. Ham hock drumstick pork short ribs
                      filet mignon hamburger alcatra porchetta ribeye chuck
                      meatloaf t-bone pork chop....
                    </TextXs>
                  </BlurredBackground>
                  <BlurredOverlay>
                    <H6 color={DarkOrange} className="mb-0">
                      Upgrade to unlock <Lock sx={{ fontSize: "14px" }} />
                    </H6>
                  </BlurredOverlay>
                </UpgradeBoxLocked>
              </UpgradeContainer>
            </Col>
          </Row>
        )}

        {(currentUser?.isAnonymous || !currentUser) && (
          <Row>
            <Col
              lg={{ offset: 4, span: 4 }}
              md={{ offset: 3, span: 6 }}
              sm={{ offset: 2, span: 8 }}
              xs={{ span: 12 }}
              className="text-align-center"
            >
              <BreakdownBottom className="justify-content-center display-column">
                <H6 className="mb-20">👇 create an account 👇</H6>
                <ButtonBrown
                  className="mt-0"
                  width="80%"
                  onClick={() =>
                    navigate("/login", { state: { linkAnonymous: true } })
                  }
                >
                  save your answers
                </ButtonBrown>
              </BreakdownBottom>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  );
};

export default ResultsMB;
