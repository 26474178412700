import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { doc, getDoc } from "firebase/firestore";
import Carousel from "react-multi-carousel";

import Layout from "../components/layout/layout";
import { Col, Container, Row } from "react-bootstrap";
import { H3, H5, H6, TextXs } from "../components/theme/text/Headings";
import HomeQuizBox, { HomeQuizBoxSm } from "../components/theme/QuizBox";
import { ButtonBrown, ButtonOutline, Button } from "../components/theme/Button";
import {
  Brown,
  Burgundy,
  DarkBrown,
  DarkOrange,
  Green,
  OffWhite,
  Orange,
  White05,
} from "../components/theme/colors";

import { db } from "../services/firebase.config";
import { useAuth } from "../services/AuthContext";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  ArrowForward,
  Bed,
  Bolt,
  Favorite,
  WineBar,
} from "@mui/icons-material";
import { Drawer } from "@mui/material";

import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import { BusinessCenter } from "@mui/icons-material";
import { AccountBalance } from "@mui/icons-material";
import { useLoading } from "../services/LoadingContext";

const ButtonBox = styled.div`
  background: ${({ gradient }) => gradient || White05};
  border-radius: 10px;
  padding: 25px 10px;
  width: 100%;
`;

const Highlight = styled.div`
  padding: 10px 10px 0 0;
  min-height: 150px;
`;

export const ProPic = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px solid ${(props) => props.borderColor || DarkBrown};
`;

export const SecPic = styled.div`
  height: 50px;
  width: 50px;
  font-size: 20px;
  border-radius: 50px;
  border: 3px solid ${(props) => props.borderColor || DarkBrown};
  background-color: ${White05};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${DarkBrown};
`;

export const PlaceholderCircle = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px dotted ${(props) => props.borderColor || DarkBrown};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${OffWhite};
`;

export const StyledLink = styled(Link)`
  text-decoration: none; /* Remove underline */
  color: inherit; /* Keep the text color inherited */
`;

const Box = styled.div`
  flex: ${(props) =>
    props.flex || 1}; /* Defaults to 1 if no flex prop is provided */
  text-align: center;
`;

const responsiveCore = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
    partialVisibilityGutter: 10,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    partialVisibilityGutter: 10,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 10,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    partialVisibilityGutter: 40,
  },
};

const responsiveDating = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    partialVisibilityGutter: 5,
  },
};

const quizConfig = {
  "best-date": {
    url: "/best-date/results",
    color: Burgundy,
    title: "Perfect Date",
  },
  wybmgf: {
    url: "/will-you-be-my-gf/results",
    color: Burgundy,
    title: "Will You Be My GF?",
  },
  "political-alignment": {
    url: "/political-alignment/results",
    color: Orange,
    title: "Political Alignment",
  },
  "sex-styles": {
    url: "/sex-styles/results",
    color: Burgundy,
    title: "Sex Styles",
  },
  "myers-briggs": {
    url: "/myers-briggs/results",
    color: DarkOrange,
    title: "Myers Briggs",
  },
};

const comingSoonQuizzes = ["compatability"];

const Home = () => {
  const { currentUserState } = useAuth();
  const { setLoading } = useLoading(); // Access global loading context

  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [startQuizURL, setStartQuizURL] = useState(null);
  const [sendQuizURL, setSendQuizURL] = useState(null);
  const [quizColor, setQuizColor] = useState(null);

  const [fDCompleted, setFDCompleted] = useState(false);
  const [pACompleted, setPACompleted] = useState(false);
  const [mBCompleted, setMBCompleted] = useState(false);
  const [ssCompleted, setSSCompleted] = useState(false);
  const [wybmgfCompleted, setWybmgfCompleted] = useState(false);

  const [sharedQuizzes, setSharedQuizzes] = useState([]);
  const [quizDetails, setQuizDetails] = useState([]);

  // State to track whether data is loaded
  const [dataLoaded, setDataLoaded] = useState(false);

  // State to track which accordion is open
  const [expandedAccordion, setExpandedAccordion] = useState("coreCheck");

  const [open, setOpen] = useState(false);

  const toggleDrawer = (state, quiz, url, color) => {
    if (selectedQuiz === quiz) {
      setSelectedQuiz(null);
      setStartQuizURL(null);
      setQuizColor(null);
    } else {
      setSelectedQuiz(quiz);
      setStartQuizURL(url);
      setQuizColor(color);
    }
    setOpen(state);
  };

  const handleClose = () => toggleDrawer(false);

  const toggleAccordion = (key) => {
    setExpandedAccordion((prevKey) => (prevKey === key ? null : key));
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUserState) return;

      setLoading(true); // Start spinner globally
      try {
        const profileDocRef = doc(db, "public-profiles", currentUserState.uid);
        const profileDoc = await getDoc(profileDocRef);

        if (profileDoc.exists()) {
          const fdQuizId = profileDoc.data().firstDateQuizId;
          const paQuizId = profileDoc.data().politicalQuizId;
          const mbQuizId = profileDoc.data().myersBriggsQuizId;
          const ssQuizId = profileDoc.data().sexStylesQuizId;
          const wybmgfQuizId = profileDoc.data().wybmgfQuizId;
          const sharedQuizIds = profileDoc.data().shared || [];

          setSharedQuizzes(sharedQuizIds);

          if (fdQuizId) {
            const docRef = doc(db, "quiz-answers", fdQuizId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists() && docSnap.data().status === "completed") {
              setFDCompleted(true);
            }
          }

          if (paQuizId) {
            const docRef = doc(db, "quiz-answers", paQuizId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists() && docSnap.data().status === "completed") {
              setPACompleted(true);
            }
          }

          if (mbQuizId) {
            const docRef = doc(db, "quiz-answers", mbQuizId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists() && docSnap.data().status === "completed") {
              setMBCompleted(true);
            }
          }

          if (ssQuizId) {
            const docRef = doc(db, "quiz-answers", ssQuizId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists() && docSnap.data().status === "completed") {
              setSSCompleted(true);
            }
          }

          if (wybmgfQuizId) {
            const docRef = doc(db, "quiz-answers", wybmgfQuizId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists() && docSnap.data().status === "completed") {
              setWybmgfCompleted(true);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfileData();
  }, [currentUserState, setLoading]);

  useEffect(() => {
    const fetchSharedQuizzes = async () => {
      if (!sharedQuizzes.length) {
        setDataLoaded(true); // Mark data as loaded if no quizzes
        return;
      }
      try {
        const quizPromises = sharedQuizzes.map(async (quizId) => {
          const quizDocRef = doc(db, "shared-quizzes", quizId);
          const quizDoc = await getDoc(quizDocRef);

          if (quizDoc.exists()) {
            const quizData = quizDoc.data();
            const otherUserUid =
              quizData.senderUid === currentUserState.uid
                ? quizData.receiverUid
                : quizData.senderUid;

            const otherUserProfileRef = doc(
              db,
              "public-profiles",
              otherUserUid
            );
            const otherUserProfileDoc = await getDoc(otherUserProfileRef);

            if (otherUserProfileDoc.exists()) {
              return {
                id: quizId,
                quizData,
                otherUserData: otherUserProfileDoc.data(),
              };
            }
          }
          return null;
        });

        const quizzes = (await Promise.all(quizPromises)).filter(Boolean);
        setQuizDetails(quizzes);
      } catch (error) {
        console.error("Error fetching shared quizzes:", error);
      } finally {
        setDataLoaded(true); // Mark all data as loaded
        setLoading(false); // Stop spinner globally
      }
    };

    fetchSharedQuizzes();
  }, [sharedQuizzes, currentUserState, setLoading]);

  useEffect(() => {
    // Dynamically update the sendQuizURL based on the selected quiz
    const fetchSendQuizURL = async () => {
      if (!currentUserState || !selectedQuiz) {
        setSendQuizURL(null);
        return;
      }

      const profileDocRef = doc(db, "public-profiles", currentUserState.uid);
      const profileDoc = await getDoc(profileDocRef);

      if (profileDoc.exists()) {
        const fdQuizId = profileDoc.data().firstDateQuizId;
        const paQuizId = profileDoc.data().politicalQuizId;
        const mbQuizId = profileDoc.data().myersBriggsQuizId;
        const ssQuizId = profileDoc.data().sexStylesQuizId;
        const wybmgfQuizId = profileDoc.data().wybmgfQuizId;

        if (selectedQuiz === "best-date" && fdQuizId) {
          setSendQuizURL(`/best-date?from=${fdQuizId}`);
        } else if (selectedQuiz === "political-alignment" && paQuizId) {
          setSendQuizURL(`/political-alignment?from=${paQuizId}`);
        } else if (selectedQuiz === "myers-briggs" && mbQuizId) {
          setSendQuizURL(`/myers-briggs?from=${mbQuizId}`);
        } else if (selectedQuiz === "sex-styles" && ssQuizId) {
          setSendQuizURL(`/sex-styles?from=${ssQuizId}`);
        } else if (selectedQuiz === "wybmgf" && wybmgfQuizId) {
          setSendQuizURL(`/will-you-be-my-gf?from=${wybmgfQuizId}`);
        } else {
          setSendQuizURL(null);
        }
      }
    };

    fetchSendQuizURL();
  }, [currentUserState, selectedQuiz]);

  const copyToClipboard = (URL) => {
    navigator.clipboard
      .writeText(URL)
      .then(() => alert("Link copied to clipboard!"))
      .catch((err) => console.error("Failed to copy: ", err));
  };

  if (!dataLoaded) {
    // Return null while waiting for data
    return null;
  }

  return (
    <Layout background={OffWhite}>
      <Col
        lg={{ offset: 4, span: 4 }}
        md={{ offset: 3, span: 6 }}
        sm={{ offset: 2, span: 8 }}
        xs={{ span: 12 }}
        className="text-align-center"
      >
        <Container className="display-flex mt-10">
          {quizDetails.length > 0 ? (
            <>
              {quizDetails
                .slice(0, 7)
                .reverse()
                .map((quizIdentifier) => {
                  const quiz = quizIdentifier.quizData;
                  const config = quiz ? quizConfig[quiz.quizName] || {} : {};

                  return (
                    <StyledLink
                      to={`${config.url}?shared=${quizIdentifier.id}`}
                    >
                      {quizIdentifier.otherUserData.photoURL ? (
                        <ProPic
                          src={quizIdentifier.otherUserData.photoURL}
                          borderColor={config.color}
                        />
                      ) : (
                        <SecPic borderColor={config.color}>
                          <H3 className="mb-0">
                            {quizIdentifier.otherUserData.displayName
                              ? quizIdentifier.otherUserData.displayName[0]
                              : "?"}
                          </H3>
                        </SecPic>
                      )}
                    </StyledLink>
                  );
                })}

              {/* Render Placeholder Circles */}
              {[...Array(7 - quizDetails.slice(0, 7).length)].map(
                (_, index) => (
                  <PlaceholderCircle key={index} />
                )
              )}
            </>
          ) : (
            // If no results, render 7 placeholder circles
            [...Array(7)].map((_, index) => <PlaceholderCircle key={index} />)
          )}
        </Container>
        <Container className="mb-15">
          <StyledLink to={`/results`}>
            <TextXs className="text-align-right mt-10" color={DarkBrown}>
              See all results <ArrowForward style={{ fontSize: "12px" }} />
            </TextXs>
          </StyledLink>
        </Container>

        <Accordion
          style={{ backgroundColor: `${DarkBrown} !important` }}
          expanded={expandedAccordion === "coreCheck"}
          onChange={() => toggleAccordion("coreCheck")}
        >
          <AccordionSummary>
            <Box flex="1">
              <H5 className="text-align-left mt-10" color={Brown}>
                Core Check
              </H5>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {expandedAccordion === "coreCheck" && (
              <Carousel
                key={`coreCheck-${expandedAccordion}`} // Added key prop
                responsive={responsiveCore}
                swipeable={true}
                draggable={true}
                partialVisible={true}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              >
                <Highlight>
                  <HomeQuizBox
                    background={DarkOrange}
                    selected={selectedQuiz === "myers-briggs"}
                    anyselected={selectedQuiz !== null}
                    onClick={() =>
                      toggleDrawer(
                        true,
                        "myers-briggs",
                        "/myers-briggs-question-1",
                        DarkOrange
                      )
                    }
                  >
                    <PsychologyAltIcon
                      className="mb-10"
                      style={{ color: `${OffWhite}` }}
                    />
                    <H5 color={OffWhite}>Myers Briggs</H5>
                  </HomeQuizBox>
                </Highlight>
                <Highlight>
                  <HomeQuizBox
                    background={Green}
                    selected={selectedQuiz === "big-five"}
                    anyselected={selectedQuiz !== null}
                    onClick={() =>
                      toggleDrawer(
                        true,
                        "big-five",
                        "/big-five-question-1",
                        Green
                      )
                    }
                  >
                    <BusinessCenter
                      className="mb-10"
                      style={{ color: `${OffWhite}` }}
                    />
                    <H5 color={OffWhite}>Big 5 Personality</H5>
                  </HomeQuizBox>
                </Highlight>
                <Highlight>
                  <HomeQuizBox
                    background={Orange}
                    selected={selectedQuiz === "political-alignment"}
                    anyselected={selectedQuiz !== null}
                    onClick={() =>
                      toggleDrawer(
                        true,
                        "political-alignment",
                        "/political-alignment-question-1",
                        Orange
                      )
                    }
                  >
                    <AccountBalance
                      className="mb-10"
                      style={{ color: `${OffWhite}` }}
                    />
                    <H5 color={OffWhite}>Political Alignment</H5>
                  </HomeQuizBox>
                </Highlight>
                <Highlight>
                  <HomeQuizBox
                    background={Burgundy}
                    selected={selectedQuiz === "sex-styles"}
                    anyselected={selectedQuiz !== null}
                    onClick={() =>
                      toggleDrawer(
                        true,
                        "sex-styles",
                        "/sex-styles-question-1",
                        Burgundy
                      )
                    }
                  >
                    <Bed className="mb-10" style={{ color: `${OffWhite}` }} />
                    <H5 color={OffWhite}>Sex Style</H5>
                  </HomeQuizBox>
                </Highlight>
              </Carousel>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ backgroundColor: `${DarkOrange} !important` }}
          expanded={expandedAccordion === "dateCheck"}
          onChange={() => toggleAccordion("dateCheck")}
        >
          <AccordionSummary>
            <Box flex="1">
              <H5 className="text-align-left mt-10" color={Burgundy}>
                Dating Check
              </H5>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {expandedAccordion === "dateCheck" && (
              <Carousel
                key={`dateCheck-${expandedAccordion}`} // Add this line
                responsive={responsiveDating}
                swipeable={true}
                draggable={true}
                partialVisible={true}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              >
                <Highlight>
                  <HomeQuizBoxSm
                    background={Burgundy}
                    selected={selectedQuiz === "wybmgf"}
                    anyselected={selectedQuiz !== null}
                    onClick={() =>
                      toggleDrawer(
                        true,
                        "wybmgf",
                        "/wybmgf-question-1",
                        Burgundy
                      )
                    }
                  >
                    <Favorite
                      className="mb-10"
                      style={{ color: `${OffWhite}` }}
                    />
                    <H5 color={OffWhite}>Are We Compatibile?</H5>
                  </HomeQuizBoxSm>
                </Highlight>
                <Highlight>
                  <HomeQuizBoxSm
                    background={Burgundy}
                    selected={selectedQuiz === "best-date"}
                    anyselected={selectedQuiz !== null}
                    onClick={() =>
                      toggleDrawer(
                        true,
                        "best-date",
                        "/best-date-question-1",
                        Burgundy
                      )
                    }
                  >
                    <WineBar
                      className="mb-10"
                      style={{ color: `${OffWhite}` }}
                    />
                    <H5 color={OffWhite}>Perfect Date</H5>
                  </HomeQuizBoxSm>
                </Highlight>
                <Highlight>
                  <HomeQuizBoxSm
                    background={Burgundy}
                    selected={selectedQuiz === "compatability"}
                    anyselected={selectedQuiz !== null}
                    onClick={() =>
                      toggleDrawer(true, "compatability", null, Burgundy)
                    }
                  >
                    <Bolt className="mb-10" style={{ color: `${OffWhite}` }} />
                    <H5 color={OffWhite}>
                      Do We <br />
                      Vibe?
                    </H5>
                  </HomeQuizBoxSm>
                </Highlight>
              </Carousel>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedAccordion === "workCheck"}
          onChange={() => toggleAccordion("workCheck")}
        >
          <AccordionSummary
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&.Mui-expanded": {
                backgroundColor: "transparent",
              },
            }}
          >
            <Box flex="1">
              <H5 className="text-align-left mt-10" color={Green}>
                Work Styles
              </H5>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {expandedAccordion === "workCheck" && (
              <Carousel
                key={`workCheck-${expandedAccordion}`} // Added key prop
                responsive={responsiveDating}
                swipeable={true}
                draggable={true}
                partialVisible={true}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              >
                <Highlight>
                  <HomeQuizBoxSm
                    background={Green}
                    selected={selectedQuiz === "big-five"}
                    anyselected={selectedQuiz !== null}
                    onClick={() =>
                      toggleDrawer(
                        true,
                        "big-five",
                        "/big-five-question-1",
                        Green
                      )
                    }
                  >
                    <BusinessCenter
                      className="mb-10"
                      style={{ color: `${OffWhite}` }}
                    />
                    <H5 color={OffWhite}>Big 5 Personality</H5>
                  </HomeQuizBoxSm>
                </Highlight>
              </Carousel>
            )}
          </AccordionDetails>
        </Accordion>
        <Drawer open={open} onClose={handleClose} anchor="bottom">
          <Row className="text-align-center">
            {comingSoonQuizzes.includes(selectedQuiz) ? (
              <div style={{ padding: "10px 30px" }}>
                <ButtonBox gradient={quizColor}>
                  <H6 style={{ color: "#fff" }}>Coming Soon!</H6>
                </ButtonBox>
              </div>
            ) : (
              <div style={{ padding: "20px 30px" }}>
                <ButtonBox gradient={OffWhite}>
                  <div className="display-flex display-column">
                    <Link to={startQuizURL}>
                      <ButtonOutline width="200px" color={quizColor}>
                        {(selectedQuiz === "best-date" && fDCompleted) ||
                        (selectedQuiz === "political-alignment" &&
                          pACompleted) ||
                        (selectedQuiz === "myers-briggs" && mBCompleted) ||
                        (selectedQuiz === "sex-styles" && ssCompleted) ||
                        (selectedQuiz === "wybmgf" && wybmgfCompleted)
                          ? "Re-Take Quiz"
                          : "Take Quiz"}
                      </ButtonOutline>
                    </Link>

                    {selectedQuiz === "best-date" && fDCompleted && (
                      <Link to="/best-date/results">
                        <Button
                          width="200px"
                          className="mt-10"
                          background={quizColor}
                        >
                          My Results
                        </Button>
                      </Link>
                    )}

                    {selectedQuiz === "political-alignment" && pACompleted && (
                      <Link to="/political-alignment/results">
                        <Button
                          width="200px"
                          className="mt-10"
                          background={quizColor}
                        >
                          My Results
                        </Button>
                      </Link>
                    )}

                    {selectedQuiz === "myers-briggs" && mBCompleted && (
                      <Link to="/myers-briggs/results">
                        <Button
                          width="200px"
                          className="mt-10"
                          background={quizColor}
                        >
                          My Results
                        </Button>
                      </Link>
                    )}

                    {selectedQuiz === "sex-styles" && ssCompleted && (
                      <Link to="/sex-styles/results">
                        <Button
                          width="200px"
                          className="mt-10"
                          background={quizColor}
                        >
                          My Results
                        </Button>
                      </Link>
                    )}

                    {selectedQuiz === "wybmgf" && wybmgfCompleted && (
                      <Link to="/will-you-be-my-gf/results">
                        <Button
                          width="200px"
                          className="mt-10"
                          background={quizColor}
                        >
                          My Results
                        </Button>
                      </Link>
                    )}
                  </div>

                  {((selectedQuiz === "wybmgf" && wybmgfCompleted) ||
                    (selectedQuiz === "sex-styles" && ssCompleted) ||
                    (selectedQuiz === "myers-briggs" && mBCompleted) ||
                    (selectedQuiz === "political-alignment" && pACompleted) ||
                    (selectedQuiz === "best-date" && fDCompleted)) && (
                    <ButtonBrown
                      width="200px"
                      className="mt-20"
                      onClick={() =>
                        copyToClipboard(
                          `${window.location.origin}${sendQuizURL}`
                        )
                      }
                    >
                      Share Quiz
                    </ButtonBrown>
                  )}
                </ButtonBox>
              </div>
            )}
          </Row>
        </Drawer>
      </Col>
    </Layout>
  );
};

export default Home;
