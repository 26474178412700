// questionsData.js
const questionsData = [
  {
    id: "1",
    nextQuestionId: "2",
    field: "movie",
    questionText: "Will you watch a show without me that we started together?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "absolutely not.",
      },
      {
        answer: "2",
        percentage: 0,
        copy: "maybe a few episodes...",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "i'll watch it, but pretend i didn't.",
      },
      {
        answer: "4",
        percentage: 100,
        copy: "i'll watch it, tell you i watched it, and then watch it again with you.",
      },
    ],
  },
  {
    id: "2",
    nextQuestionId: "3",
    field: "date",
    questionText: "What date was our first date?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "December 31st, 2024",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "January 1st, 2025",
      },
      {
        answer: "3",
        percentage: 33,
        copy: "January 3rd, 2025",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "January 6th, 2025",
      },
    ],
  },
  {
    id: "3",
    nextQuestionId: "4",
    field: "trips",
    questionText: "What's your favorite thing we've done together so far?",
    answers: [
      {
        answer: "1",
        percentage: 33,
        copy: "Go to San Diego.",
      },
      {
        answer: "2",
        percentage: 100,
        copy: "Get hot pot.",
      },
      {
        answer: "3",
        percentage: 66,
        copy: "Break the internet.",
      },
      {
        answer: "4",
        percentage: 0,
        copy: "Listen to the whole Brakence album.",
      },
    ],
  },
  {
    id: "4",
    nextQuestionId: "5",
    field: "crazy",
    questionText: "What's the craziest thing we've done so far?",
    answers: [
      {
        answer: "1",
        percentage: 0,
        copy: "Share our locations before our 3rd date.",
      },
      {
        answer: "2",
        percentage: 66,
        copy: "Becoming exclusive after our second date.",
      },
      {
        answer: "3",
        percentage: 100,
        copy: "Turning Barney's into a lesbian dating spot.",
      },
      {
        answer: "4",
        percentage: 33,
        copy: "Getting really high, forgetting we're really high, and then getting a seafood boil.",
      },
    ],
  },
  {
    id: "5",
    field: "favorite",
    questionText: "What's my favorite thing about you?",
    answers: [
      {
        answer: "1",
        percentage: 100,
        copy: "How you hold space for me",
      },
      {
        answer: "2",
        percentage: 33,
        copy: "Your drive and determination",
      },
      {
        answer: "3",
        percentage: 0,
        copy: "How beautiful you are",
      },
      {
        answer: "4",
        percentage: 66,
        copy: "How safe I feel with you",
      },
    ],
  },
];

export default questionsData;
