import { useEffect, useState } from "react";

import { Col } from "react-bootstrap";

import { doc, getDoc } from "firebase/firestore";

import Layout from "../components/layout/layout";
import { ResultsBox } from "../components/theme/QuizBox";
import { H5, H5Norm, Paragraph, Span } from "../components/theme/text/Headings";
import {
  Burgundy,
  DarkBrown,
  DarkOrange,
  Orange,
  White05,
} from "../components/theme/colors";
import { StyledLink } from "./Home";

import { db } from "../services/firebase.config";
import { useAuth } from "../services/AuthContext";
import { useLoading } from "../services/LoadingContext";
import styled from "styled-components";

export const ProPic = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 30px;
  border-radius: 50px;
  border: 3px solid ${(props) => props.borderColor || DarkBrown};
`;

export const SecPic = styled.div`
  height: 30px;
  width: 30px;
  margin-right: 30px;
  border-radius: 50px;
  border: 3px solid ${(props) => props.borderColor || DarkBrown};
  background-color: ${White05};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${DarkBrown};
`;

export const flexPic = styled.div`
  flex: 2;
`;

export const flexText = styled.div`
  flex: 3;
`;

const Results = () => {
  const { currentUserState } = useAuth();
  const { setLoading } = useLoading(); // Access global loading context

  const [dataLoaded, setDataLoaded] = useState(false);

  const [sharedQuizzes, setSharedQuizzes] = useState([]);
  const [quizDetails, setQuizDetails] = useState([]);

  // Map of quiz names to their respective URLs and gradients
  const quizConfig = {
    "best-date": {
      url: "/best-date/results",
      color: Burgundy,
      title: "Perfect Date",
    },
    "political-alignment": {
      url: "/political-alignment/results",
      color: Orange,
      title: "Political Alignment",
    },
    "sex-styles": {
      url: "/sex-styles/results",
      color: Burgundy,
      title: "Sex Styles",
    },
    "myers-briggs": {
      url: "/myers-briggs/results",
      color: DarkOrange,
      title: "Myers Briggs",
    },
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUserState) return;

      setLoading(true);
      try {
        const profileDocRef = doc(db, "public-profiles", currentUserState.uid);
        const profileDoc = await getDoc(profileDocRef);

        if (profileDoc.exists()) {
          const sharedQuizIds = profileDoc.data().shared || [];
          setSharedQuizzes(sharedQuizIds);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfileData();
  }, [currentUserState, setLoading]);

  useEffect(() => {
    const fetchSharedQuizzes = async () => {
      if (!sharedQuizzes.length) {
        setDataLoaded(true); // Mark data as loaded if no quizzes
        return;
      }

      try {
        const quizPromises = sharedQuizzes.map(async (quizId) => {
          const quizDocRef = doc(db, "shared-quizzes", quizId);
          const quizDoc = await getDoc(quizDocRef);

          if (quizDoc.exists()) {
            const quizData = quizDoc.data();
            const otherUserUid =
              quizData.senderUid === currentUserState.uid
                ? quizData.receiverUid
                : quizData.senderUid;

            const otherUserProfileRef = doc(
              db,
              "public-profiles",
              otherUserUid
            );
            const otherUserProfileDoc = await getDoc(otherUserProfileRef);

            if (otherUserProfileDoc.exists()) {
              return {
                id: quizId,
                quizData,
                otherUserData: otherUserProfileDoc.data(),
              };
            }
          }
          return null;
        });

        const quizzes = (await Promise.all(quizPromises)).filter(Boolean);
        setQuizDetails(quizzes);
      } catch (error) {
        console.error("Error fetching shared quizzes:", error);
      } finally {
        setDataLoaded(true); // Mark data as fully loaded
        setLoading(false); // Stop spinner globally
      }
    };

    if (sharedQuizzes.length) {
      fetchSharedQuizzes();
    }
  }, [sharedQuizzes, currentUserState, setLoading]);

  if (!dataLoaded) {
    return null;
  }

  return (
    <Layout>
      {currentUserState !== null && (
        <Col
          lg={{ offset: 4, span: 4 }}
          md={{ offset: 3, span: 6 }}
          sm={{ offset: 2, span: 8 }}
          xs={{ span: 12 }}
          className="text-align-center"
        >
          <H5 className="mb-30" color={DarkBrown}>
            Compatability Results
          </H5>

          {quizDetails.length > 0 ? (
            quizDetails
              .slice()
              .reverse()
              .map((quizIdentifier) => {
                const quiz = quizIdentifier.quizData;
                const config = quiz ? quizConfig[quiz.quizName] || {} : {};
                return (
                  <StyledLink
                    key={quizIdentifier.id}
                    className="mb-3"
                    to={`${config.url}?shared=${quizIdentifier.id}`}
                  >
                    <ResultsBox
                      key={quizIdentifier.id}
                      background={config.color}
                    >
                      <flexText style={{ width: "100%" }}>
                        <H5Norm
                          color={DarkBrown}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          {quizIdentifier.otherUserData.displayName} just took
                          your{" "}
                          <Span color={config.color}>
                            {config.title || "UNKNOWN"}
                          </Span>{" "}
                          quiz.
                        </H5Norm>
                      </flexText>
                      <flexPic>
                        {quizIdentifier.otherUserData.photoURL ? (
                          <ProPic
                            src={quizIdentifier.otherUserData.photoURL}
                            borderColor={config.color}
                          />
                        ) : (
                          <SecPic borderColor={config.color}>
                            <H5Norm className="mb-0">
                              {quizIdentifier.otherUserData.displayName
                                ? quizIdentifier.otherUserData.displayName[0]
                                : "?"}
                            </H5Norm>
                          </SecPic>
                        )}
                      </flexPic>
                    </ResultsBox>
                  </StyledLink>
                );
              })
          ) : (
            <Paragraph className="mt-100">
              No results found! Send your friends a link from the home page to
              see their responses here.
            </Paragraph>
          )}
        </Col>
      )}
    </Layout>
  );
};

export default Results;
