import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

import Layout from "../../components/layout/layout";
import { H1, H3, H4, H5 } from "../../components/theme/text/Headings";
import { ResultsPageBox } from "../../components/theme/QuizBox";
import { Burgundy } from "../../components/theme/colors";
import { SecPic } from "../Home";

import { auth, db } from "../../services/firebase.config";

const ProPic = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px solid ${Burgundy};
`;

const ResultsWYBMGF = () => {
  const [loading, setLoading] = useState(true); // Loading state
  const [currentUser, setCurrentUser] = useState(null); // Track current user

  const [photoURL, setPhotoURL] = useState("");
  const [displayName, setDisplayName] = useState("");

  const [quizId2, setQuizId2] = useState("");
  const [displayName2, setDisplayName2] = useState("");
  const [photoURL2, setPhotoURL2] = useState("");

  const [searchParams] = useSearchParams();
  const sharedId = searchParams.get("shared");

  // Listen for auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (!user) setLoading(false); // Stop loading if no user
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUser) return;

      const profileDocRef = doc(db, "public-profiles", currentUser.uid);
      const profileDoc = await getDoc(profileDocRef);

      if (profileDoc.exists()) {
        const data = profileDoc.data();
        setPhotoURL(data.photoURL || "");
        setDisplayName(data.displayName || "");
      }
    };

    if (currentUser) fetchProfileData();
  }, [currentUser]);

  useEffect(() => {
    const getSharedCollection = async () => {
      if (!sharedId || !currentUser) {
        setLoading(false); // Stop loading if no shared ID or user
        return;
      }

      if (sharedId) {
        const sharedDocRef = doc(db, "shared-quizzes", sharedId);
        const sharedDoc = await getDoc(sharedDocRef);

        if (sharedDoc.data().senderUid === currentUser.uid) {
          const profile2DocRef = doc(
            db,
            "public-profiles",
            sharedDoc.data().receiverUid
          );
          const profile2Doc = await getDoc(profile2DocRef);

          if (profile2Doc.exists()) {
            const data = profile2Doc.data();
            setPhotoURL2(data.photoURL || "");
            setDisplayName2(data.displayName || "");
          }
        } else if (sharedDoc.data().senderUid !== currentUser.uid) {
          setQuizId2(sharedDoc.data().senderQuizId);

          const profile2DocRef = doc(
            db,
            "public-profiles",
            sharedDoc.data().senderUid
          );
          const profile2Doc = await getDoc(profile2DocRef);

          if (profile2Doc.exists()) {
            const data = profile2Doc.data();

            setPhotoURL2(data.photoURL || "");
            setDisplayName2(data.displayName || "");
          }
        }
      }
      setLoading(false);
    };
    getSharedCollection();
  }, [sharedId, currentUser, quizId2]);

  if (loading) {
    // Show a loading spinner or placeholder
    return (
      <Layout>
        <Container>
          <Row className="mt-10 text-align-center">
            <Col>
              <H5>Loading your results...</H5>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container>
        <Row>
          <Col
            lg={{ offset: 3, span: 6 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <ResultsPageBox
              style={{ flexDirection: "column", marginTop: "20vh" }}
            >
              <H4 className="mb-20">Hey Megan! We're a 100% Match!</H4>
              <H1 className="mb-40 mt-5-real">Will You Be My Girlfriend? ❤️</H1>

              {!sharedId ? (
                <>
                  {photoURL ? (
                    <ProPic src={photoURL} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName ? displayName[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                </>
              ) : (
                <div className="display-flex">
                  {photoURL ? (
                    <ProPic src={photoURL} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName ? displayName[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                  <CompareArrowsIcon className="mt-15" />
                  {photoURL2 ? (
                    <ProPic src={photoURL2} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName2 ? displayName2[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                </div>
              )}
            </ResultsPageBox>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ResultsWYBMGF;
