import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import {
  doc,
  addDoc,
  collection,
  updateDoc,
  serverTimestamp,
  getDoc,
  arrayUnion,
} from "firebase/firestore";

import Layout from "../components/layout/layout";
import { H5, H6, TextSm } from "../components/theme/text/Headings";
import {
  QuizAnswerBox,
  QuestionContainer,
  QuestionHeader,
  QuestionBody,
} from "../components/theme/QuizBox";

import { auth, db } from "../services/firebase.config";
import { Burgundy } from "../components/theme/colors";

const QuestionTemplate = ({ question, answers, field, nextQuestionId }) => {
  const navigate = useNavigate();
  const currentUser = auth.currentUser;

  const [searchParams] = useSearchParams();
  const sharedId = searchParams.get("shared");
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [quizDocId, setQuizDocId] = useState(null);

  useEffect(() => {
    const initializeQuizDoc = async () => {
      if (!currentUser) {
        alert("You need to be logged in to start the quiz.");
        return;
      }

      const userProfileRef = doc(db, "public-profiles", currentUser.uid);
      const userProfileDoc = await getDoc(userProfileRef);

      if (userProfileDoc.exists() && userProfileDoc.data().wybmgfQuizId) {
        // Use the existing quiz document if it exists
        setQuizDocId(userProfileDoc.data().wybmgfQuizId);
      } else {
        if (!quizDocId) {
          // Create a new quiz document only if `quizDocId` is not set
          const newQuizRef = await addDoc(collection(db, "quiz-answers"), {
            uid: currentUser.uid,
            answers: {},
            created: serverTimestamp(),
            quizName: "wybmgf", // Correct quiz name
          });
          setQuizDocId(newQuizRef.id);

          // Save the new `quizDocId` to the user's profile document
          await updateDoc(userProfileRef, { wybmgfQuizId: newQuizRef.id }); // Correctly update the field
        }
      }
    };

    initializeQuizDoc();
  }, [currentUser, quizDocId]);

  const handleSelectQuiz = async (answer, percentage) => {
    if (!quizDocId) {
      console.error("Quiz document ID is not set. Cannot update answer.");
      return;
    }

    if (selectedQuiz === answer) {
      setSelectedQuiz(null);
      return;
    }

    setSelectedQuiz(answer);

    if (!currentUser) {
      alert("You need to be logged in to submit an answer.");
      return;
    }

    const quizDocRef = doc(db, "quiz-answers", quizDocId);

    try {
      // Check if this is the final question
      const isFinalQuestion = !nextQuestionId;

      // Update the document with the selected answer for the current question
      await updateDoc(quizDocRef, {
        [`answers.${question.id}`]: { answer, percentage, field },
        ...(isFinalQuestion && { status: "completed" }), // Mark the quiz as completed if it's the final question
      });

      // Additional shared quiz handling only if `sharedId` exists
      if (isFinalQuestion && sharedId) {
        const sharedDocRef = doc(db, "shared-quizzes", sharedId);

        await updateDoc(sharedDocRef, {
          receiverQuizId: quizDocId, // Set the quizDocId as the receiverQuizId
        });

        // Retrieve the `shared-quizzes` document to get both UIDs
        const sharedDoc = await getDoc(sharedDocRef);
        if (sharedDoc.exists()) {
          const { senderUid, receiverUid } = sharedDoc.data();
          const senderProfileRef = doc(db, "public-profiles", senderUid);
          const receiverProfileRef = doc(db, "public-profiles", receiverUid);

          await Promise.all([
            updateDoc(senderProfileRef, {
              shared: arrayUnion(sharedId),
            }),
            updateDoc(receiverProfileRef, {
              shared: arrayUnion(sharedId),
            }),
          ]);
        }
      }

      // Navigate to the results page if it's the final question
      if (isFinalQuestion) {
        const nextUrl = sharedId
          ? `/will-you-be-my-gf/results?shared=${sharedId}`
          : `/will-you-be-my-gf/results`;
        navigate(nextUrl);
      } else {
        // Otherwise, go to the next question
        const nextUrl = sharedId
          ? `/wybmgf-question-${nextQuestionId}?shared=${sharedId}`
          : `/wybmgf-question-${nextQuestionId}`;
        navigate(nextUrl);
      }
    } catch (error) {
      console.error("Error adding answer:", error);
    }
  };

  return (
    <Layout>
      <Container>
        <Row className="mt-20">
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ offset: 1, span: 10 }}
            className="text-align-center"
          >
            <H6 className="mb-30">Are We Compatibile?</H6>
            <QuestionContainer className="mb-30">
              <QuestionHeader background={Burgundy}>
                <TextSm>Question {question.id} out of 5</TextSm>
              </QuestionHeader>
              <QuestionBody>
                <H5 className="mt-10">{question.questionText}</H5>
              </QuestionBody>
            </QuestionContainer>

            {answers.map((answer, index) => (
              <Row key={index}>
                <Col>
                  <QuizAnswerBox
                    selected={selectedQuiz === answer.copy}
                    anyselected={selectedQuiz !== null}
                    onClick={() =>
                      handleSelectQuiz(answer.answer, answer.percentage)
                    }
                  >
                    <TextSm>{answer.copy}</TextSm>
                  </QuizAnswerBox>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default QuestionTemplate;
