import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { signInAnonymously } from "firebase/auth";

import styled from "styled-components";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { TextField } from "@mui/material";

import Layout from "../../components/layout/layout";
import { H3, H5, TextSm } from "../../components/theme/text/Headings";
import { LandingPageBox } from "../../components/theme/QuizBox";
import { DarkBrown, Burgundy } from "../../components/theme/colors";
import { ButtonBrown } from "../../components/theme/Button";

import { auth, db } from "../../services/firebase.config";

const ProPic = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px solid ${Burgundy};
`;

export const SecPic = styled.div`
  height: 50px;
  width: 50px;
  font-size: 20px;
  border-radius: 50px;
  border: 3px solid ${Burgundy};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${DarkBrown};
`;

const LandingSS = () => {
  const navigate = useNavigate();
  const currentUser = auth.currentUser;

  //sender info
  const [displayName, setDisplayName] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [senderUid, setSenderUid] = useState("");

  const [receiverName, setReceiverName] = useState("");
  const [receiverPhoto, setReceiverPhoto] = useState("");
  const [searchParams] = useSearchParams();

  const fromQuizId = searchParams.get("from");

  //send data when submitting name
  const authenticateAndSetDisplayName = async () => {
    try {
      let user = auth.currentUser;

      if (!user) {
        // If the user is not logged in, create an anonymous account
        const signInResult = await signInAnonymously(auth);
        user = signInResult.user;

        // Update Firestore with the new user's profile
        const userDocRef = doc(db, "public-profiles", user.uid);
        await setDoc(
          userDocRef,
          {
            uid: user.uid,
            displayName: receiverName,
          },
          { merge: true }
        );
      } else {
        // If the user is already logged in, update their profile if necessary
        const userDocRef = doc(db, "public-profiles", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          // Create a profile if it doesn't exist
          await setDoc(
            userDocRef,
            {
              uid: user.uid,
              displayName: receiverName,
            },
            { merge: true }
          );
        }
      }

      // Get the receiver's quiz ID from their profile
      const receiverProfileRef = doc(db, "public-profiles", user.uid);
      const receiverProfileDoc = await getDoc(receiverProfileRef);
      const receiverQuizId =
        receiverProfileDoc.exists() &&
        receiverProfileDoc.data().sexStylesQuizId;

      // Create a new shared quiz
      const newSharedRef = await addDoc(collection(db, "shared-quizzes"), {
        quizName: "sex-styles",
        receiverUid: user.uid,
        receiverQuizId: receiverQuizId || null,
        senderUid: senderUid,
        senderQuizId: fromQuizId,
        created: serverTimestamp(),
      });
      // If the receiver already has a quiz ID and it's completed, update shared arrays and navigate to results
      if (receiverQuizId) {
        const receiverQuizRef = doc(db, "quiz-answers", receiverQuizId);
        const receiverQuizDoc = await getDoc(receiverQuizRef);

        if (
          receiverQuizDoc.exists() &&
          receiverQuizDoc.data().status === "completed"
        ) {
          // Update shared array for both sender and receiver
          const senderProfileRef = doc(db, "public-profiles", senderUid);

          await Promise.all([
            updateDoc(senderProfileRef, {
              shared: arrayUnion(newSharedRef.id),
            }),
            updateDoc(receiverProfileRef, {
              shared: arrayUnion(newSharedRef.id),
            }),
          ]);

          // Navigate to results page
          return navigate(`/sex-styles/results?shared=${newSharedRef.id}`);
        }
      }

      // If no completed quiz, navigate to the first question
      navigate(`/sex-styles-question-1?shared=${newSharedRef.id}`);
    } catch (error) {
      console.error("Error during quiz initialization:", error);
    }
  };

  useEffect(() => {
    const fetchLoggedIn = async () => {
      if (currentUser) {
        const userDocRef = doc(db, "public-profiles", currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc) {
          setReceiverName(userDoc.data().displayName);
          setReceiverPhoto(userDoc.data().photoURL);
        }
      }
    };

    fetchLoggedIn();
  }, [currentUser]);

  //fetch sender data
  useEffect(() => {
    const fetchSenderData = async () => {
      if (fromQuizId) {
        const quizDocRef = doc(db, "quiz-answers", fromQuizId);
        const quizDoc = await getDoc(quizDocRef);

        if (quizDoc.exists()) {
          const userDocRef = doc(db, "public-profiles", quizDoc.data().uid);
          const userDoc = await getDoc(userDocRef);
          setDisplayName(userDoc.data().displayName || "");
          setPhotoURL(userDoc.data().photoURL || "");
          setSenderUid(userDoc.data().uid || "");
        } else {
          console.log("User data not found.");
        }
      }
    };

    fetchSenderData();
  }, [fromQuizId]);

  return (
    <Layout>
      <Container>
        <Row className="mt-10">
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <LandingPageBox style={{ flexDirection: "column" }}>
              <TextSm>
                {displayName || "Your friend"} want's a Vibe Check!
              </TextSm>

              <H5 className="mb-20 mt-10">What is your sex style?</H5>
              <div className="display-flex">
                {photoURL ? (
                  <ProPic src={photoURL} />
                ) : (
                  <SecPic>
                    <H3 className="mb-0">
                      {displayName ? displayName[0] : "?"}
                    </H3>
                  </SecPic>
                )}
                <CompareArrowsIcon className="mt-15" />
                {receiverPhoto ? (
                  <ProPic src={receiverPhoto} />
                ) : (
                  <SecPic>
                    <H3 className="mb-0">
                      {receiverName ? receiverName[0] : "?"}
                    </H3>
                  </SecPic>
                )}
              </div>
              <TextField
                className="mt-30"
                fullWidth
                label="what's your name?"
                variant="outlined"
                value={receiverName}
                onChange={(e) => setReceiverName(e.target.value)}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px" },
                  "& .MuiInputLabel-root": { fontSize: "13px" },
                }}
              />
              <ButtonBrown
                className="mt-20"
                width="100%"
                onClick={authenticateAndSetDisplayName}
              >
                Let's get started
              </ButtonBrown>
            </LandingPageBox>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          ></Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default LandingSS;
